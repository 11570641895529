import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";

import Image from 'react-bootstrap/Image'
import { Container } from 'react-bootstrap';

const content = [
    "For over two decades, Jane M. Fearn-Zimmer has been a trusted advocate for families across Southern New Jersey, offering dedicated, responsive legal counsel in elder law, estate planning, Medicaid and veterans’ benefits planning, and special needs care. Jane works closely with seniors, individuals with disabilities, and their caregivers to secure the best possible care, preserve family wealth, and access essential public benefits — allowing families to protect their financial future.",
    "As the only attorney in South Jersey with extensive experience in elder care, veterans’ benefits, and taxation, Jane brings a unique skill set to her practice. She is the only attorney in the region who has served as both Chair of the New Jersey State Bar Association Elder and Disability Law Section, is VA-certified, and also holds a Master of Laws (LL.M.) in Taxation.",
    "Our concierge practice offers direct, one-on-one access to Jane, who personally guides every client through the legal complexities of their situation. Whether you require simple advice or sophisticated, creative planning, Jane’s depth of experience ensures tailored solutions that best serve your family's needs."
];

const values = [
    "Helping seniors and individuals with special needs obtain quality care while preserving family wealth.",
    "Seek practical, strategic and personalized legal solutions.",
    "Navigating Medicaid and veterans: benefits to ensure families can access public resources.",
    "Crafting customized elder care and estate plans that safeguard assets for future generations."
]

export default function HomePage() {
    return <><Row>
        <Col sm={7}>
            <h5 style={{ fontFamily: "Spectral-Regular", textAlign: "left", fontWeight: "bold", paddingLeft: "10%" }}>Fearn Law LLC</h5>
            <p style={{ fontFamily: "Spectral-Regular", textAlign: "left", lineHeight: 1, paddingLeft: "10%" }}>{content.map(c => <><p style={{ paddingTop: 5 }}>{c}</p></>)}</p>
            <p style={{ fontFamily: "Spectral-Regular", textAlign: "left", lineHeight: 1, paddingLeft: "10%" }}>Key Services include:</p>

            <ul style={{ paddingLeft: "15%" }}>
                {values.map(value => <li style={{ textAlign: "left", lineHeight: 1, fontFamily: "Spectral-Regular" }}>{value}</li>)}
            </ul>
            <Link to="/Attorney-Profiles"><p style={{ fontFamily: "Spectral-Regular" }}>Find out more.</p></Link>
        </Col>
        <Col sm={5}>
            <Image
                height="auto"
                width="100%"
                src={require("../media/images/jane-portrait-new.jpeg")}
                rounded
            />
        </Col>
    </Row>
    </>
}